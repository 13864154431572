/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
import { Auth } from '@aws-amplify/auth';
import api from '@/lib/api';
import userApp from './userApp';

// Utils
function timeout(ms) {
  // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}
async function sleep(fn, ms) {
  await timeout(ms);
  return fn();
}

let tokenRefreshTimer = null;
//  after 1hour, the user has to reconnect
const SESSION_DURATION_INACTIVE_MS = 1 * 60 * 60 * 1000;
function willRefreshToken(commit) {
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }
  // refresh cognito token every 5min
  tokenRefreshTimer = setTimeout(async () => {
    const lastRequestDate = localStorage.getItem('session_lastRequest_date');
    if (
      lastRequestDate
      && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS
    ) {
      // after 1hour, the user has to reconnect
      commit(
        'notification/SET_SNACK_DATAS',
        {
          text: 'Votre session a expirée',
          color: 'warn',
        },
        { root: true },
      );
      await Auth.signOut();
      // the Hub will redirect the user to /login if necessary (see router/index.js)
    } else {
      if (
        lastRequestDate
        && Date.now() - lastRequestDate
          > SESSION_DURATION_INACTIVE_MS - 2 * 60 * 1000
      ) {
        commit(
          'notification/SET_SNACK_DATAS',
          {
            text: 'Votre session va expirer dans moins de 2 minutes',
            color: 'warn',
          },
          { root: true },
        );
      }
      try {
        // console.warn('currentSession refreshed');
        const currentSession = await Auth.currentSession();
        const { jwtToken } = currentSession.accessToken;
        api.authenticate(jwtToken);
        willRefreshToken(commit);
      } catch (e) {
        // Network error
        console.warn(e);
        if (e.code === 'NotAuthorizedException') {
          //  message: "Refresh Token has expired"
          commit(
            'notification/SET_SNACK_DATAS',
            {
              text: 'Votre session a expirée',
              color: 'warn',
            },
            { root: true },
          );
          await Auth.signOut();
        } else {
          willRefreshToken(commit);
        }
      }
    }
  }, 5 * 60 * 1000);
}

// Initial state
const initialState = {
  cognito: null,
  habilitation: null,
  entities: [],
  entitySelected: { entityId: '9999', noemt: '1111', label: 'test' },
  permissions: {},
  loading: false,
};

// Actions
const actions = {
  setEntitySelected({ commit }, v) {
    commit('SET_ENTITY', v);
  },

  signOutDefaultActions({ commit }) {
    commit('SET_USER', null);
  },
  async signOut() {
    try {
      // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-out
      await Auth.signOut();
      // no need to  commit('SET_USER', null);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  },
  async loadUserAndCredentials({ commit, dispatch }, { router, onSignIn }) {
    try {
      const lastRequestDate = localStorage.getItem('session_lastRequest_date');
      if (
        !onSignIn
        && lastRequestDate
        && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS
      ) {
        // after 1hour, the user has to reconnect
        await Auth.signOut();
      } else {
        await Auth.currentAuthenticatedUser().then((user) => commit('SET_USER', user));
        await dispatch('loadCredentials');
        willRefreshToken(commit);
        const route = router.currentRoute;
        // add &&!hasUser to solve 'Avoided redundant navigation to current location'
        if (route.query && route.query.redirect) {
          router.push(route.query.redirect);
        } else if (route.name === 'Login') {
          router.push({ name: 'Home' });
        }
      }
    } catch (e) {
      console.warn('loadUserAndCredentials', e);
    }
  },
  async loadCredentials({ commit, state, dispatch }, params = {}) {
    if (!state.loading) {
      commit('SET_LOADING', true);
      try {
        // Récup user
        let myToken = params.jwtToken;
        if (!myToken) {
          const session = await Auth.currentSession();
          if (session) {
            myToken = session.accessToken.jwtToken;
          }
        }
        if (myToken) {
          // commit('SET_USER', user);
          api.authenticate(myToken);
          await dispatch('loadCredentialsHabilitation');

          commit('SET_LOADING', false);
        } else {
          // no session
          await sleep(() => {
            commit('SET_LOADING', false);
          }, 500);
        }
      } catch (e) {
        console.warn('loadCredentials error', e);
        // no session
        await sleep(() => {
          commit('SET_LOADING', false);
        }, 500);
      }
    }
  },
  // async load({commit})
};

// Mutations
const mutations = {
  SET_USER(state, user) {
    state.cognito = user;

    if (!user) {
      clearTimeout(tokenRefreshTimer);
      localStorage.setItem('session_lastRequest_date', null);
      api.authenticate(null);
      state.entitySelected = null;
      state.entities = [];
      state.permissions = {};
      state.habilitation = null;
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ENTITY(state, v) {
    state.entitySelected = v;
  },
  SET_ENTITIES(state, entities) {
    state.entities = entities;
  },
  SET_PERMISSIONS(state, perms) {
    state.permissions = perms;
  },
  SET_HABILITATION(state, v) {
    state.habilitation = v;
  },
};

const getters = {
  //
};

export default {
  namespaced: true,
  state: { ...initialState, ...userApp.state },
  actions: { ...actions, ...userApp.actions },
  mutations: { ...mutations, ...userApp.mutations },
  getters: { ...getters, ...userApp.getters },
};
