import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0053A1',
        secondary: '#F28C00',
        accent: '#7AB2E1',
      },
    },
  },
  lang: {
    locales: {
      fr,
    },
    current: 'fr',
  },
});
