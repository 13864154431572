<template>
  <v-menu
    v-model="menu"
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="mx-auto ml-2 hidden-sm-and-down"
        v-bind="attrs"
        v-on="on"
         data-test="bt-profil"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar >
            <v-icon large>
              mdi-account-circle
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="!hiUser">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title>
              {{ hiUser.identity.firstname | capitalize }}
              {{ hiUser.identity.lastname | capitalize }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ hiUser.identity.email }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click="menu = false"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <UserAccesses v-if="!permissions['don.entities.list-all']" />

      <v-card-text v-if="permissions['don.habilitations.access']">
        <ManageAccesses />
      </v-card-text>

      <v-card-actions>
        <ChangePassword
          v-if="
            user &&
            user.signInUserSession &&
            !['ChangePwd'].includes($route.name)
          "
          class="mr-5"
        />
        <v-spacer />
        <Logout v-if="hiUser" />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    UserAccesses: () => import('@/components/User/UserAccesses.vue'),
    ManageAccesses: () => import('@/components/User/ManageAccessesBtn.vue'),
    ChangePassword: () => import('./ChangePasswordBtn.vue'),
    Logout: () => import('./LogoutBtn.vue'),
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.cognito,
      hiUser: (state) => state.user.habilitation,
    }),
    ...mapState('user', [
      'permissions',
    ]),

  },
};
</script>
