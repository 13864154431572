<template>
   <v-autocomplete
    v-model="entitySelected"
    :items="storesSorted"
    :auto-select-first="true"
    label="Rôle utilisateur"
    color="primary"
    item-color="secondary"
    hide-details
    dense
    offset-y
    filled
    outlined
    class="rounded"
    item-text="name"
    return-object
    @input="input"
    data-test="s-navBarShop"
  >

    <template v-slot:[`selection`]="{ item }">
      <v-icon left> mdi-account-convert </v-icon>
      <span > {{ item.name  }} </span>
    </template>
  </v-autocomplete>
</template>

<script>
import roles from './role';

export default {
  data() {
    const storesSorted = roles;
    return {
      storesSorted,
      entitySelected: storesSorted[0],
    };
  },

  methods: {
    input(e) {
      this.$store.commit('user/SET_HABILITATION', e);
      this.$store.dispatch('user/refreshPermissions');
    },
  },
};
</script>
