<template>
  <v-autocomplete
    v-model="entitySelected"
    :items="storesSorted"
    :disabled="['Association'].includes($route.name)"
    :auto-select-first="true"
    label="Entité"
    color="primary"
    item-color="secondary"
    hide-details
    dense
    offset-y
    clearable
    :filled="filled"
    outlined
    class="rounded"
    item-text="name"
    return-object
    @input="input"
    data-test="s-navBarShop"
  >
    <template v-slot:[`selection`]="{ item }">
      <v-icon left> mdi-store </v-icon>
      <span> {{ item.name }} </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: Object,
    filled: {
      type: Boolean,
      default: false,
    },
    manadatory: Boolean,
  },
  data() {
    return {
      entitySelected: this.value,
    };
  },
  computed: {
    ...mapState({
      entities: (state) => state.user.entities,
    }),

    storesSorted() {
      return this.entities;
    },
  },

  methods: {
    input(e) {
      if (e || !this.manadatory) {
        // force select an entity, otherwise we do not know what to do for supervisor
        this.$emit('input', e);
      }
    },
  },
  watch: {
    value(v) {
      this.entitySelected = v;
    },
  },
};
</script>

<style></style>
