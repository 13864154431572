<template>
  <v-navigation-drawer
    v-if="user"
    expand-on-hover
    :mini-variant="true"
    permanent
    app
    clipped
    data-test="leftMenu"
  >
    <!-- <left-nav-user-menu /> -->

    <v-divider class="mb-2 grey lighten-2"></v-divider>
    <v-list nav dense data-test="leftMenuListItem">
      <v-list-item-group v-model="selectedItem">
        <div v-for="(m, i) in items" :key="i" class="menu-left">
          <v-list-group v-if="m.submenu" :value="true" no-action>
            <template v-slot:activator>
              <v-list-item-icon class="mr-2 my-2">
                <v-icon v-text="m.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="m.title"
                  :data-test="'menu-' + m.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="s in m.submenu"
              :key="s.name"
              :link="!s.link"
              :to="s.link ? undefined : s.path"
              :href="s.link"
              :target="s.link ? '_blank' : undefined"
              class="pl-5"
              :data-test="'menu-' + s.name"
            >
              <v-list-item-icon class="mr-2 my-2">
                <v-icon v-text="s.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="s.title"></v-list-item-title>
              <v-icon v-if="s.link" small> mdi-open-in-new</v-icon>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :to="m.path"
            link
            :data-test="'menu-' + m.name"
            class="px-2"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ m.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ m.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import routes from '@/router/routesLeftMenu';
// import LeftNavUserMenu from './LeftNavUserMenu.vue';

export default {
  Name: 'LeftNavigation',
  // components: {
  //   LeftNavUserMenu,
  // },
  data() {
    return {
      drawer: true,
      items: [],
      selectedItem: 0,
      mini: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.habilitation,
      permissions: (state) => state.user.permissions,
    }),
  },
  methods: {
    ...mapActions('user', ['hasPermissionIn', 'signOut']),

    async keepMenu(myRoutes) {
      return (
        await Promise.all(
          myRoutes.map(async (r) => {
            if (r.meta && r.meta.permissions) {
              const ok = await this.hasPermissionIn(r.meta.permissions);
              return ok ? { ...r, title: this.$t(r.title) } : undefined;
            }
            if (r.submenu) {
              const submenu = await this.keepMenu(r.submenu);
              if (submenu.length === 1) {
                const remaning = submenu[0];
                const title = this.$t(r.title);
                return { ...remaning, title: `${title} ${remaning.title}`, icon: r.icon };
              }
              return { ...r, submenu, title: this.$t(r.title) };
            }
            return { ...r, title: this.$t(r.title) };
          }),
        )
      )
        .filter((r) => r);
    },

    async updateMenu() {
      this.items = await this.keepMenu(routes);
    },
  },
  created() {
    this.updateMenu();
  },
  watch: {
    permissions() {
      this.updateMenu();
    },
  },
};
</script>

<style>
#app .v-navigation-drawer__border {
  border-right: 1px solid #e0e0e0;
}
</style>
