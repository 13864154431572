import api from '@/lib/api';

const initialState = {
  documents: [],
  faq: [],
};

const getters = {};

const actions = {
  async load({ commit }, key) {
    const { data } = await api.name(`${key}Load`).get(`/documentation/${key}`);
    commit('SET', { key, data });
  },
};

const mutations = {
  SET(state, { key, data }) {
    state[key] = data;
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
