import Vue from 'vue';
import Vuex from 'vuex';

import notification from '@edel/edel-ui/src/store/modules/notification';
import loader from '@edel/edel-ui/src/store/modules/loader';
import user from './modules/userConnected';
import association from './modules/association';
import report from './modules/report';
import documentation from './modules/documentation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notification,
    loader,
    association,
    report,
    documentation,
  },
});
