import Vue from 'vue';
import VueRouter from 'vue-router';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

import api from '@/lib/api';
import store from '../store/index';
import myRoutes from './routes';

Vue.use(VueRouter);

const pathLogin = '/login';

Vue.config.productionTip = false;

const routes = [...myRoutes,
  {
    path: pathLogin,
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
  {
    name: 'ResetPwd',
    path: '/reset-pwd',
    component: () => import(/* webpackChunkName: "ResetPwd" */ '@/views/ResetPwd.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

async function onBefore(to, from, next) {
  // https://router.vuejs.org/guide/advanced/meta.html
  // eslint-disable-next-line no-constant-condition
  if (to.matched.some((record) => record.meta.requiresAuth)) {
  // if (to.matched.some((record) => record.name !== 'Login')) {
    const { cognito, loading } = store.state.user;
    // console.log('onBefore', cognito);
    if (cognito && !loading) {
      // filter undefined
      const permissionsByRoute = to.matched.map((record) => record.meta.permissions)
        .filter((a) => a);
      if (permissionsByRoute.length > 0) {
        for (let index = 0; index < permissionsByRoute.length; index += 1) {
          const permissions = permissionsByRoute[index];
          // eslint-disable-next-line no-await-in-loop
          const ok = await store.dispatch('user/hasPermissionIn', permissions);
          if (!ok) {
            console.error('Unauthorized. Required:', permissions);
            return false;
          }
        }
      }
    } else {
      // copy query parameter to keep credentials from Alertel
      return next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
          ...to.query,
        },
      });
    }
    return next();
  }
  return next();
}
router.beforeResolve(onBefore);

Hub.listen('auth', (data) => {
  // console.log('Amplify Hub:', data.payload.event);
  switch (data.payload.event) {
    case 'signIn':
      store.dispatch('user/loadUserAndCredentials', { router, onSignIn: true });
      break;
    case 'signOut':
      store.dispatch('user/signOutActions');
      if (router.currentRoute.name !== 'Login') {
        router.push(
          {
            path: '/login',
          },
        );
      }
      break;
    case 'tokenRefresh':
      Auth.currentSession().then((currentSession) => {
        const { jwtToken } = currentSession.accessToken;
        api.authenticate(jwtToken);
      });
      break;

    case 'forgotPasswordSubmit':
      store.commit(
        'notification/SET_SNACK_DATAS',
        {
          t: 'cognito.pwdNew',
          color: 'success',
        },
      );
      break;
    default:
      break;
  }
});

export default router;
