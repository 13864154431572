/* eslint-disable no-unused-vars */
import {
  add, sub, isAfter, differenceInDays,
} from 'date-fns';
import base64 from '@edel/edel-ui/src/lib/base64';

import api from '@/lib/api';
import assoModels from '../../services/assoModels';

const initialState = {
  searchText: null,
  searchStatus: null,
  searchAll: false,
  associations: [],
  association: null,
  paginate: false,
  associationInitial: null,
  pagination: {
    currentPage: 1,
    size: 10,
  },
};

const getters = {};

function toView(e) {
  const c = {
    ...e,
  };
  if (e.expires_at) {
    c.expires_at = new Date(e.expires_at);
    if (isAfter(new Date(), c.expires_at)) {
      c.countdown = 0;
    } else {
      c.countdown = differenceInDays(new Date(), c.expires_at);
    }
  }
  return c;
}

let setSearchTimeout = null;
const actions = {
  async initSearch({ commit, dispatch }) {
    if (await dispatch('user/hasPermissionIn', ['don.asso.refuse-lvl1'], { root: true })) {
      commit('UPDATE_SEARCH', {
        searchStatus: assoModels.statusListValidationReal.find((e) => e.name === 'WAITING_VALIDATION_BO'),
        searchAll: true,
      });
    } else if (await dispatch('user/hasPermissionIn', ['don.asso.doc.rib.validate'], { root: true })) {
      commit('UPDATE_SEARCH', {
        searchStatus: assoModels.statusListValidationReal.find((e) => e.name === 'WAITING_VALIDATION_COMPTA'),
        searchAll: true,
      });
    } else if (await dispatch('user/hasPermissionIn', ['don.asso.doc.refuse'], { root: true })) {
      commit('UPDATE_SEARCH', {
        searchStatus: assoModels.statusListValidationReal.find((e) => e.name === 'WAITING_VALIDATION_DRC'),
        searchAll: true,
      });
    } else {
      commit('UPDATE_SEARCH', {
        searchStatus: null,
        searchAll: false,
      });
    }
  },

  async exportAsso() {
    const { data } = await api
      .name('assoExport')
      .get('/asso/export', { timeout: 30000 });
    const fileLink = document.createElement('a');
    fileLink.target = '_blank';
    fileLink.href = data.Location;
    document.body.appendChild(fileLink);
    fileLink.click();
  },

  updateSearch({ commit, dispatch }, search) {
    commit('UPDATE_SEARCH', search);
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      dispatch('search');
    }, 800);
  },

  async register({ rootState, commit }, asso) {
    const entity = rootState.user.entitySelected;
    const isCQDDForEntity = rootState.user.permissions['don.entities.list-all']
    && !entity.id.startsWith('cqdd');

    const { data } = await api
      .name('assoRegister')
      .post(`/entity/${entity.id}/registerAsso?persist=${!!isCQDDForEntity}`, asso);
    commit('SET_ASSOCIATION', toView(data));
    return { entityId: data.entityId, assoId: data.assoId };
  },

  async getAssoDetails({ commit, state }, { entityId, assoId }) {
    if (state.association
       && state.association.entityId === entityId
       && state.association.assoId === assoId) {
      console.log('keeping local data');
    } else {
      await api.name('assoGet').get(`/asso/${assoId}/${entityId}`)
        .then((result) => {
          commit('SET_ASSOCIATION', toView(result.data));
        });
    }
  },

  async updateAsso({ commit, state, dispatch }, { message, isCreating }) {
    await api.name('assoUpdate')
      .post(
        `/asso/${state.association.assoId}/${state.association.entityId}?isCreating=${isCreating}`,
        state.association,
      )
      .then((result) => {
        dispatch('notification/add', {
          type: 'success',
          message,
        }, { root: true });
        commit('SET_ASSOCIATION', toView(result.data));
      });
  },

  updateStatus({
    commit, dispatch, rootState, state,
  }, {
    entityId,
    assoId,
    action,
    comment, router,
  }) {
    const data = {
      action,
      comment,
    };
    if (rootState.user.isFaking) {
      let newStatus;
      switch (action) {
        case 'ask-validation':
          newStatus = 'WAITING_VALIDATION_BO';
          break;
        case 'activate':
          newStatus = 'ACTIVATED';
          state.association.expires_at = sub(add(new Date(), { years: 1 }), { days: 15 });
          state.association.infomil_at = add(new Date(), { days: 1 });
          break;
        case 'update':
          newStatus = 'WAITING_DOCUMENT';
          break;
        default:
          throw new Error(`invalid action: ${action}`);
      }

      const r = { ...state.association, status: newStatus };

      commit('SET_ASSOCIATION', r);
      return 'ok';
    }
    return api.name('assoUpdateStatus').post(`/asso/${assoId}/${entityId}/action`, data)
      .then((result) => {
        commit('SET_ASSOCIATION', toView(result.data));
        if (action !== 'update') {
          router.push({ name: 'AssociationList' });
        }

        dispatch('notification/add', {
          type: 'success',
          message: 'Statut mis à jour',
        }, { root: true });
      });
  },

  async cancelDetailEdit({ commit, state }) {
    commit('SET_ASSOCIATION', state.associationInitial);
  },

  async deleteAsso({ commit, state }) {
    const { assoId, entityId } = state.association;
    return api.name('assoDelete').delete(`/asso/${assoId}/${entityId}`);
  },

  deleteDoc({ state }, item) {
    if (item.s3Path) {
      if (!state.association.deleteDocs) {
        state.association.deleteDocs = [];
      }
      state.association.deleteDocs.push({ ...item });
    }
  },

  async searchDirect({ rootState }, { apiName, filters }) {
    const { entitySelected } = rootState.user;
    const params = {
      search: base64.encryptJsonToBase64({ ...filters, entityId: entitySelected.id }),
    };

    const { data } = await api.name(apiName).get('/asso', { params });
    // {continuationToken, count, items} = data
    return data;
  },

  async search({ commit, state, rootState }) {
    const { searchText, searchStatus, searchAll } = state;
    const filters = { text: searchText, status: (searchStatus || {}).keys, searchAll };
    const { entitySelected } = rootState.user;
    if (entitySelected) {
      filters.entityId = entitySelected.id;
    }

    const params = {
      page: state.pagination.currentPage,
      pageSize: state.pagination.size,
      search: base64.encryptJsonToBase64(filters),
    };

    const { status, data } = await api.name('associationList').get('/asso', { params });
    // {continuationToken, count, items} = data
    commit('SET_ASSOCIATIONS', data.items.map((e) => toView(e)));

    // Pagination check
    if (data.continuationToken) {
      params.page += 1;
      const continuation = await api.name('associationList').get('/asso', { params });

      if (continuation.data.count > 0) {
        commit('SET_PAGINATE', true);
      } else {
        commit('SET_PAGINATE', false);
      }
    } else {
      commit('SET_PAGINATE', false);
    }
  },

  resetSearch({ commit, dispatch }) {
    commit('UPDATE_SEARCH', { searchStatus: null, searchText: null });

    dispatch('search');
  },

  async getAssoBySiret({ _commit, _state, _dispatch }, siret) {
    const { data } = await api.name('getAssoBySiret').get(`/callSireneApi/${siret}`);
    return data;
  },

  async getAssoByRNA({ _commit, _state, _dispatch }, rna) {
    const { data } = await api.name('getAssoByRNA').get(`/callRnaApi/${rna}`);
    return data;
  },
};

// mutations
const mutations = {
  SET_ASSOCIATIONS(state, data) {
    state.associations = data;
  },

  SET_ASSOCIATION(state, association) {
    state.association = association;
    state.associationInitial = { ...association };
    if (association) {
      state.associationInitial.emails = association.emails.map((e) => ({ ...e }));
      state.associationInitial.documents = association.documents.map((e) => ({ ...e }));
    }
  },

  UPDATE_SEARCH(state, search) {
    state.pagination.currentPage = 1;
    Object.keys(search).forEach((key) => {
      state[key] = search[key];
    });
  },

  SET_PAGINATE(state, data) {
    state.paginate = data;
  },
};

export default {
  scope: 'ALL',
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
