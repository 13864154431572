const statusListDefault = [
  { name: 'WAITING_DOCUMENT', keys: ['WAITING_DOCUMENT'] },
  { name: 'VALID', keys: ['VALID'] },
  { name: 'ACTIVATED', keys: ['ACTIVATED'] },
  { name: 'BLOCKED', keys: ['BLOCKED'] },
  { name: 'TO_RENEW', keys: ['TO_RENEW'] },
  { name: 'EXPIRED', keys: ['EXPIRED'] },
];

const statusListValidationOperator = [
  {
    name: 'WAITING_VALIDATION',
    keys: ['WAITING_VALIDATION_BO', 'WAITING_VALIDATION_LVL2', 'WAITING_VALIDATION_COMPTA', 'WAITING_VALIDATION_DRC'],
  },
];

const statusListValidationReal = [
  { name: 'WAITING_VALIDATION_BO', keys: ['WAITING_VALIDATION_BO'] },
  { name: 'WAITING_VALIDATION_COMPTA', keys: ['WAITING_VALIDATION_LVL2', 'WAITING_VALIDATION_COMPTA'] },
  { name: 'WAITING_VALIDATION_DRC', keys: ['WAITING_VALIDATION_LVL2', 'WAITING_VALIDATION_DRC'] },
];

function getStatusLabel(status, permissions, asso) {
  let label = status;

  switch (status) {
    case 'WAITING_VALIDATION_BO':
      if (permissions['don.asso.list']) {
        label = 'WAITING_VALIDATION';
      } else if (permissions['don.asso.ask-validation-DRC']) {
        label = 'TO_VALIDATE_BO';
      }
      return label;

    case 'WAITING_VALIDATION_LVL2':
      if (permissions['don.asso.list']) {
        label = 'WAITING_VALIDATION';
      } else if (permissions['don.asso.validate']) {
        label = 'TO_VALIDATE_DRC';
      } else if (permissions['don.asso.doc.rib.validate']) {
        label = 'TO_VALIDATE_COMPTA';
      }
      return label;

    case 'WAITING_VALIDATION_DRC':
      if (permissions['don.asso.list']) {
        label = 'WAITING_VALIDATION';
      } else if (permissions['don.asso.validate']) {
        label = 'TO_VALIDATE_DRC';
      }
      return label;

    case 'WAITING_VALIDATION_COMPTA':
      if (permissions['don.asso.list']) {
        label = 'WAITING_VALIDATION';
      } else if (permissions['don.asso.doc.rib.validate']) {
        label = 'TO_VALIDATE_COMPTA';
      }
      return label;

    case 'VALID':
      if (asso && asso.scope === 'national') {
        label = 'VALID_CQDD';
      }
      return label;

    default:
      return label;
  }
}

function getStatusColor(status) {
  switch (status) {
    case 'ACTIVATED':
      return 'success';
    case 'EXPIRATED':
      return 'error';
    case 'BLOCKED':
      return 'dark';
    default:
      return 'warning';
  }
}

export default {
  statusListDefault,
  statusListValidationOperator,
  statusListValidationReal,
  getStatusLabel,
  getStatusColor,
};
