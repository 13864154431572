import api from '@/lib/api';
import roles from '../../components/role';

const REQUIRED_PERMISSIONS = [];

const initialState = {

  isFaking: false,
};

const getters = {
  //
};

// actions
const actions = {
  async loadCredentialsHabilitation({ commit, dispatch }, useApi = true) {
    if (useApi) {
      // eslint-disable-next-line no-unused-vars
      const { status, data } = await api.name('user').get('/auth/v2');
      if (status === 200) {
        commit('SET_HABILITATION', data);
      }
    } else {
      commit('SET_USER', {});
      commit('SET_HABILITATION', roles[0]);
    }
    dispatch('refreshPermissions');

    dispatch('association/initSearch', '', { root: true });
  },

  async refreshPermissions({ commit, state, dispatch }) {
    const { pByBundleId, bundlesByMerchantId } = state.habilitation;
    const bundleIds = Object.keys(pByBundleId)
      .filter(
        (id) => REQUIRED_PERMISSIONS.length === 0
          || pByBundleId[id].find((p) => REQUIRED_PERMISSIONS.includes(p.key)),
      )
      .map((e) => +e);
    const merchants = Object.keys(bundlesByMerchantId)
      .filter((id) => bundlesByMerchantId[id].bundlesIds.some((e) => bundleIds.includes(e)))
      .map((id) => ({ id, ...bundlesByMerchantId[id] }))
      .filter((m) => m.name)
      .sort((a, b) => {
        if (a.name === 'CQDD') {
          return -1;
        } if (b.name === 'CQDD') {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });

    commit('SET_ENTITIES', merchants);
    if (merchants.length > 0) {
      await dispatch('setEntity', merchants[0]);
      if (state.permissions['calculated.seeValidation']) {
        await dispatch('setEntity', undefined);
      }
    }
  },

  async setEntity({ commit, state }, data) {
    // const { data } = await api.name('merchant').get(`/merchant/${id}`);
    commit('SET_ENTITY', data);
    if (data) {
      if (state.isFaking) {
        const fakingPermissionsToKeep = {
          'don.asso.fake-update': true,
          'don.asso.fake-ask-validation': true,
          'don.asso.fake-add': true,
          'don.asso.fake-activate': true,
        };
        if (data.id.startsWith('cqdd-')) {
          commit('SET_PERMISSIONS', {
            ...fakingPermissionsToKeep,
            'don.support.contact': true,
            'don.report.list': true,
            'don.report.download': true,
            'don.faq.list': true,
            'don.entities.list-all': true,
            'don.doc.list': true,
            'don.doc.download': true,
            'don.asso.update': true,
            'don.asso.list-all-local': true,
            'don.asso.list': true,
            'don.asso.doc-upload': true,
            'don.asso.doc-add-new': true,
            'don.asso.ask-validation': true,
            'don.asso.ask-update': true,
            'don.asso.add': true,
            'don.asso.activate': true,
          });
        } else {
          commit('SET_PERMISSIONS', {
            ...fakingPermissionsToKeep,
            'don.support.contact': true,
            'don.report.list': true,
            'don.report.download': true,
            'don.habilitations.access': true,
            'don.faq.list': true,
            'don.doc.list': true,
            'don.doc.download': true,
            'don.asso.update': true,
            'don.asso.list-all-nation': true,
            'don.asso.list': true,
            'don.asso.doc-upload': true,
            'don.asso.doc-add-new': true,
            'don.asso.ask-validation': true,
            'don.asso.ask-update': true,
            'don.asso.add': true,
            'don.asso.activate': true,
          });
        }
      } else {
        const { pByBundleId, bundlesByMerchantId } = state.habilitation;
        const permissions = bundlesByMerchantId[
          data.id
        ].bundlesIds.map((bundleId) => pByBundleId[bundleId].map((e) => e.key));
        const merged = [].concat([], ...permissions).reduce((acc, e) => {
          acc[e] = true;
          return acc;
        }, {});
        if (merged['don.asso.ask-validation-DRC']
        || merged['don.asso.validate'] || merged['don.asso.doc.rib.refuse']) {
          merged['calculated.seeValidation'] = true;
        }
        commit('SET_PERMISSIONS', merged);
      }
    }
  },

  updateFaking({ commit, dispatch, state }, v) {
    commit('SET_FAKING', v);
    dispatch('setEntity', state.entitySelected || state.entities[0]);
  },

  hasPermissionIn({ state }, perms) {
    // perms can be ['identities.']
    if (state.habilitation) {
      return Object.values(state.habilitation.pByBundleId)
        .some((permissions) => permissions
          .some((p) => perms.some((perm) => p.key.startsWith(perm))));
    }
    return false;
  },

  signOutActions({ dispatch, commit }) {
    commit('SET_FAKING', false);
    dispatch('signOutDefaultActions', null);
  },
};

// mutations
const mutations = {

  SET_FAKING(state, v) {
    state.isFaking = v;
  },

};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
