<template>
  <v-app>
    <left-navigation />
    <top-navigation />
    <v-main>
      <router-view />

      <!-- <e-footer :appVersion="appVersion" /> -->
    </v-main>
    <e-notif />
    <overlay />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import LeftNavigation from './components/Layout/LeftNavigation.vue';
import TopNavigation from './components/Layout/TopNavigation.vue';
import Overlay from './components/Layout/Overlay.vue';

export default {
  name: 'App',

  components: {
    TopNavigation,
    LeftNavigation,
    Overlay,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },

  methods: {
    ...mapActions('user', [
      'loadUserAndCredentials',
      'loadCredentialsHabilitation',
    ]),
  },

  created() {
    setTimeout(() => {
      // use setTimeout otherwise query is empty and path /
      const { query } = this.$route;
      if (query && query.a) {
        console.log('created', 'user from Alertel: do not load credentials');
      } else {
        // EDITME TODO: to remove
        // this.loadCredentialsHabilitation(false);
        this.loadUserAndCredentials({ router: this.$router, onSignIn: false });
      }
    }, 1000);
  },
};
</script>
<style lang="scss">
.theme--light.v-application {
  background-color: #fafafa !important;
}
</style>
