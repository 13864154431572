import Vue from 'vue';

// Amplify modules declaration
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import ComponentLibrary from '@edel/edel-ui';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import title from './mixins/title';
import './filters';
import vee from './plugins/vee-validate';
import awsExports from './aws-exports';
import './locales/AmplifyI18n';
import api from './lib/api';

Vue.config.productionTip = false;

// Amplify setup
Amplify.configure(awsExports);

// You can get the current config object
Auth.configure();

Vue.use(ComponentLibrary);

api.connectStore(store);
vee(i18n);
Vue.mixin(title);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
