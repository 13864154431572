<template>
  <v-app-bar clipped-left app flat color="grey lighten-5">
    <!-- <v-app-bar-nav-icon
      v-if="!['Login'].includes($route.name)"
      @click.stop="$emit('toggle-mobiledrawer')"
      class="hidden-md-and-up"
    /> -->
    <v-toolbar-title class="headline noselect pl-1">
      <router-link to="/" custom v-slot="{ navigate }" style="cursor: pointer;">
      <!-- https://next.router.vuejs.org/guide/migration/#removal-of-append-prop-in-router-link -->
        <span @click="navigate" @keypress.enter="navigate" role="link" tabindex="-1" >
          <span  class="primary--text">Don sur</span>
          <span class="font-weight-light secondary--text"> TPE</span>
        </span>
      </router-link>
      <span class="subtitle-1 grey--text hidden-sm-and-down">&nbsp;Banque Edel</span>
    </v-toolbar-title>
    <v-spacer />

    <div
      v-if="stage !== 'prod'"
      class="font-weight-bold error--text"
    >
      <v-icon color="error">mdi-alert</v-icon>
      Environnement de recette ({{ stage }})
      <v-icon color="error">mdi-alert</v-icon>
    </div>
    <v-spacer />

    <template v-if="user">
      <div class="mr-3">
        <select-role v-if="false" />
        <v-switch v-if="permissions['don.asso.fake-add']"
            hide-details
            v-model="isFaking_ro"
            label="Simuler rôle Opérateur"
             data-test="bt-fake-operator"
          ></v-switch>
      </div>
      <div>
        <select-entity v-if="entities.length > 1"
         v-model="entitySelected" filled
         :manadatory="permissions['calculated.seeValidation'] !== true" />
      </div>

      <ProfilPopup />
    </template>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SelectEntity from '../lib/SelectEntity.vue';
import SelectRole from '../SelectRole.vue';
import ProfilPopup from '../User/ProfilPopup.vue';

export default {
  name: 'TopNavigation',
  components: { SelectEntity, SelectRole, ProfilPopup },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      stage: process.env.VUE_APP_STAGE,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.habilitation,
      entitySelectedRO: (state) => state.user.entitySelected,
    }),
    ...mapState('user', [
      'permissions', 'isFaking', 'entities',
    ]),

    entitySelected: {
      get() {
        return this.entitySelectedRO;
      },
      set(v) {
        this.setEntity(v);
      },
    },
    isFaking_ro: {
      get() {
        return this.isFaking;
      },
      set(v) {
        this.updateFaking(v);
      },
    },
  },

  methods: {
    ...mapActions('user', ['setEntity', 'updateFaking']),
  },
};
</script>
