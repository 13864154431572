import base64 from '@edel/edel-ui/src/lib/base64';
import api from '@/lib/api';

const initialState = {
  reportsAsso: [],
  reportsMag: [],
  assoSearched: [],
};

const getters = {};

function toView(e) {
  const c = {
    ...e,
    created_at: new Date(e.LastModified),
    dateFromT: new Date(e.dateFrom),
    dateToT: new Date(e.dateTo),
  };

  return c;
}
let setSearchTimeout = null;

const actions = {

  async listReportMag({ commit, rootState }) {
    const { entitySelected } = rootState.user;
    const params = {};
    if (entitySelected) {
      params.entityId = entitySelected.id;
    }

    const { data } = await api.name('reportList').get('/report', { params });
    commit('SET_MAG_REPORTS', data.Contents.map((e) => toView(e)));
  },

  async listReportAsso({ commit }, assoId) {
    const params = {
      assoId, // assoId: 2,
    };

    const { data } = await api.name('reportList').get('/reportAsso', { params });
    commit('SET_ASSO_REPORTS', data.Contents.map((e) => toView(e)));
  },

  async get(_, item) {
    let resp;
    if (item.entityId) {
      resp = await api.name('reportGet').get(`/report/${item.entityId}/${item.filename}`);
    } else {
      resp = await api.name('reportGet').get(`/reportAsso/${item.filename}`);
    }
    const { data } = resp;
    console.log(item, data);
    const fileLink = document.createElement('a');
    fileLink.target = '_blank';
    fileLink.href = data.Location;
    document.body.appendChild(fileLink);
    fileLink.click();
  },

  async search({ commit }, search) {
    const filters = search ? {
      text: search.text,
    } : {};

    const params = {
      search: base64.encryptJsonToBase64(filters),
    };
    const { data } = await api.name('assoSearch').get('/assoSearch', { params });
    commit('SET_ASSOCIATIONS_SEARCHED', data.items
      .map((e) => {
        const libelleCourt = e.libelleCourt1 ? ` / ${e.libelleCourt1.replace(/  +/g, ' ')}` : '';
        const libelleDisplayed = `${e.libelleLong}${libelleCourt}`;
        return { ...e, libelleDisplayed };
      }));
  },

  async searchAsso({ dispatch }, search) {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      dispatch('search', search);
    }, 800);
  },

};

const mutations = {
  SET_MAG_REPORTS(state, data) {
    state.reportsMag = data;
  },

  SET_ASSO_REPORTS(state, data) {
    state.reportsAsso = data;
  },

  SET_ASSOCIATIONS_SEARCHED(state, data) {
    state.assoSearched = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
