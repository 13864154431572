import Vue from 'vue';
import {
  isValid,
  format,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  parseISO,
  isBefore,
  isAfter,
  getISOWeek,
} from 'date-fns';
import fr from 'date-fns/locale/fr';

Vue.filter('formatDate', (date) => {
  if (!isValid(date)) return '';
  return format(date, 'dd/MM/yyyy');
});

Vue.filter('formatDateShort', (date) => {
  date = parseISO(date);
  if (!isValid(date)) return '';
  return format(date, 'dd/MM/yy');
});

Vue.filter('formatHistDate', (date) => {
  date = parseISO(date);
  if (!isValid(date)) return '';
  return format(date, 'dd/MM/yyyy');
});

Vue.filter('formatTime', (date) => {
  date = parseISO(date);
  if (!isValid(date)) return '';
  return format(date, 'HH:mm:ss');
});

Vue.filter('formatDateCountdown', (date) => {
  if (!isValid(date)) return '';

  if (isAfter(new Date(), date)) {
    return '0 jours';
  }

  return formatDistanceToNowStrict(date, { unit: 'day', locale: fr });
});

Vue.filter('formatDateDistance', (date) => {
  if (!isValid(date)) return '';

  let distance = '';

  if (isBefore(date, new Date())) {
    distance = `il y a ${formatDistanceToNow(date, { locale: fr })}`;
  } else {
    distance = `dans ${formatDistanceToNow(date, { locale: fr })}`;
  }

  return distance;
});

Vue.filter('formatDateMonth', (date) => {
  if (!isValid(date)) return '';
  return format(date, 'LLLL yyyy', { locale: fr });
});

Vue.filter('formatDateWeek', (date) => {
  if (!isValid(date)) return '';
  return getISOWeek(date);
});
