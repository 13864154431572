<template>
  <v-overlay :value="showOverlay" z-index="999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      showOverlay: (state) => state.user.loading,
    }),
  },
};
</script>
