const routes = [
  {
    name: 'AssociationList',
    path: '/association',
    component: () => import(/* webpackChunkName: "AssociationList" */ '@/views/AssociationList.vue'),
    meta: { requiresAuth: true, permissions: ['don.asso.'] },
    title: 'menu.association',
    icon: 'mdi-charity',
  },
  {
    name: 'Reports',
    title: 'menu.reports',
    icon: 'mdi-file-cabinet',
    submenu: [
      {
        name: 'ReportsAsso',
        path: '/reports/asso',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/ReportsAsso.vue'),
        meta: { requiresAuth: true, permissions: ['don.asso.list-all-local'] },
        title: 'menu.association',
        icon: 'mdi-charity',
      },
      {
        name: 'ReportsMag',
        path: '/reports/mag',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/ReportsMag.vue'),
        meta: { requiresAuth: true, permissions: ['don.report.list'] },
        title: 'menu.entity',
        icon: 'mdi-store',
      },
    ],
  },
  {
    name: 'Documentation',
    path: '/documentation',
    component: () => import(/* webpackChunkName: "Documentation" */ '@/views/Documentation.vue'),
    meta: { requiresAuth: true, permissions: ['don.doc.list'] },
    title: 'menu.documentation',
    icon: 'mdi-bookshelf',
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: () => import(/* webpackChunkName: "FAQ" */ '@/views/FAQ.vue'),
    meta: { requiresAuth: true, permissions: ['don.faq.list'] },
    title: 'menu.faq',
    icon: 'mdi-frequently-asked-questions',
  },
  {
    name: 'Contact',
    path: '/contact',
    component: () => import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue'),
    meta: { requiresAuth: true, permissions: ['don.support.contact'] },
    title: 'menu.contact',
    icon: 'mdi-message',
  },
];

export default routes;
