import routesInMenu from './routesLeftMenu';

const routes = [
  ...routesInMenu.reduce((acc, e) => {
    if (e.submenu) {
      acc.push(...e.submenu);
    }
    if (e.path) {
      acc.push(e);
    }
    return acc;
  }, []),
  {
    name: 'Association',
    path: '/association/:assoId/:entityId',
    component: () => import(/* webpackChunkName: "Association" */ '@/views/AssociationDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ChangePwd',
    path: '/pwd',
    component: () => import(/* webpackChunkName: "ChangePwd" */ '@/views/ChangePwd.vue'),
    meta: { requiresAuth: true },
  },
  {
    // https://router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
    name: 'Home',
    path: '/',
    redirect: { name: 'AssociationList' },
  },
  {
    name: 'Redirect',
    path: '/redirect',
    beforeEnter: (to) => {
      window.open(`${to.params.redirect}/${to.params.path}?${JSON.stringify(to.query)}`, '_blank');
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
  },
];

export default routes;
